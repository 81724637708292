/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { getBrandName } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot, getValidatedZipcode } from '../../../state/ducks/App/App-Selectors';
import { getUserType } from '../../../state/ducks/App/ducks/Common/Common-Selectors';
import useGetAccessTokenSafely from '../../gql/hooks/useGetAccessTokenSafely';
import { GRAPHQL_ENV } from '../../gql';
import findProductRecommendationsSF from '../../gql/queries/findProductRecommendationsSF';
import useBrowserUUID from '../useBrowserUUID';

/**
 * @description - used to communicate with customer-personalize API for personalized content.
 * @param { string } campaignFeature - salesforce campaign name
 * @param {boolean} isSympathySku -
 * @param {string} partNumber -
 * @param {object} targeting -
 * @param {string} indivId -
 * @param { object } apolloOptions - overrides | additional options to pass to useQuery API
 * @param { object } queryName
 * @returns { object } - { loading, error, data, variables } -> add variables.skip to loading handling and variables.eidError to error handling
 */

const useSalesforceProductRecommendationQuery = ({
    campaignFeature,
    isSympathySku,
    partNumber,
    productName,
    targeting,
    filterByZipCode = false,
    apolloOptions = {},
    queryName = 'PersonalizedContent',
}) => {
    const isBot = useSelector(getIsBot);
    if (isBot) {
        return {
            loading: false, error: false, data: null, variables: { skip: false },
        };
    }

    const query = findProductRecommendationsSF(queryName);
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - ${campaignFeature}`;
    const validatedZipCode = useSelector(getValidatedZipcode);
    // set sessionLoginState according to user login state
    const userType = useSelector(getUserType);

    const userAttributes = [];
    userAttributes.push({ name: 'currentBrand', value: brand });
    userAttributes.push({ name: 'isSympathy', value: isSympathySku ? 'true' : 'false' });
    userAttributes.push({ name: 'sessionLoginState', value: userType === 'R' ? 'true' : 'false' });
    if (partNumber) {
        userAttributes.push({ name: 'referenceProductId', value: partNumber });
    }
    if (productName) userAttributes.push({ name: 'productName', value: productName });

    const browserUUID = useBrowserUUID();

    const user = {
        anonymousId: browserUUID,
    };

    if (filterByZipCode && validatedZipCode) user['zipCode'] = validatedZipCode;

    const jwt = useGetAccessTokenSafely();
    // skip until jwt is loading
    const skip = Boolean(!jwt || !browserUUID);

    return useQuery(query, {
        variables: (skip) ? { skip } : {
            // use default variables directly
            brand,
            environment: GRAPHQL_ENV,
            user,
            userAttributes,
            targeting: {
                interactionName,
                ...targeting,
            },
        },
        context: { api: 'personalizedContent', jwt },
        skip,
        fetchPolicy: 'no-cache',
        ...apolloOptions,
    });
};

export default useSalesforceProductRecommendationQuery;
